.shop-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}



.exchange-platform h2{
    color: white;
    font-size: 30px;
    font-weight: 600;
    width: 70%;
}

.exchange-platform img{
    max-width: 85%;
    height: 60px;
}

.exchange-platform p{
    width: 95%;
    min-height: 160px;
    font-family: "Sawarabi Gothic", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: rgb(236, 236, 236);
    font-size: 20px;
    margin: 10px 0;
}

.exchange-platform a{
    color: white;
    font-size: 30px;
    text-decoration: none;
    margin-top: 10px;
}

.exchange-platform a img{
    width: 55px;
    height: auto;
    margin-left: 15px;
    margin-bottom: -10px;
}

@media only screen and (max-width: 1500px){
    .exchange-platform h2{
        font-size: 25px;
    }
    .exchange-platform p{
        font-size: 18px;
    }
    .exchange-platform a{
        font-size: 25px;
    }
}

@media only screen and (max-width: 1300px){
    .exchange-platform h2{
        font-size: 20px;
    }
    .exchange-platform p{
        font-size: 16px;
    }
    .exchange-platform a{
        font-size: 20px;
    }

    .shop-container{
        
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px){
    .shop-container{
        grid-template-columns: 1fr;
    }

    
}