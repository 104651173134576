@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&family=Sawarabi+Gothic&display=swap');

:root{
  --backgroundColor: #2a273d;
  --backgroundTransparent: #2a273d00;
}

body {
  margin: 0;
  font-family: "Rubik Doodle Shadow", system-ui;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  display: block;
  margin: 0 auto;
  width: 90%;
 
  background-image: linear-gradient(var(--backgroundColor) , var(--backgroundTransparent) 20% 100%), linear-gradient(to right, var(--backgroundColor) ,  var(--backgroundTransparent)), url('./assets/background.webp');

}

.glowing-text{
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, red , orange );
  text-shadow: 0 0 15px rgba(255, 0, 0, 0.507);
}

/* ------------------------------NAVBAR---------------------------- */

.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 15px;
}

.logo{
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img{
  display: inline-block;
  width: 80px;
  height: auto;
}

.base-logo{
  width: 61px !important;
  margin-left: 5px;
}

.logo h3{
  display: inline-block;
  color: white;
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.socials{
  padding-inline-start: 0;
}

.socials li{
  display: inline-block;
  margin-right: 80px;
}

.socials li:first-child{
  font-weight: 900;
}

.socials li:last-child{
  margin-right: 0;
}

.socials li a{
  color: white;
  font-size: 30px;
  text-decoration: none;
}

.active{
  font-weight: 900;
}

.socials li a:hover{
  font-weight: 900;
}

.glass-button {
  font-family: "Rubik Doodle Shadow", system-ui;
  font-weight: 900;
  width: fit-content;
  cursor: pointer;
  border: none;
  padding: 10px 30px;
  color: white;
  font-size: 25px;
  border-radius: 4px;
  box-shadow: inset 0px 3px 5px rgba(255,255,255,0.5), 0px 0px 10px rgba(0,0,0,0.15);
  background: rgb(2,0,36);
  background: linear-gradient(45deg, rgba(2,0,36,0) 5%, rgba(255,255,255,.5) 6%, rgba(255,255,255,0) 9%, rgba(255,255,255,.5) 10%, rgba(255,255,255,0) 17%, rgba(255,255,255,.5) 19%, rgba(255,255,255,0) 21%);
  background-size: 150%;
  background-position: right;
  transition: 0.5s;
  margin-right: 30px;
}

.glass-button:hover {
    background-position: left;
    color: white;
    box-shadow: inset 0px 3px 5px rgba(255,255,255,1), 0px 0px 10px rgba(0,0,0,0.25);
}

.glass-button:focus {
    outline: none;
}

.glass-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  width: 70%;
  background: rgba(255,255,255, 0.05);
  backdrop-filter: blur(3px);
  border-top: 1px solid rgba(255,255,255, 0.2);
  border-left: 1px solid rgba(255,255,255, 0.2);
  box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
}

.footer{
  font-family: "Sawarabi Gothic", sans-serif;
  width: calc(100% - 84px);
  padding: 40px;
  margin: 30px 0 0;
  color: white;
  font-size: 25px;
}

@media only screen and (max-width: 1500px){
  .socials li{
    margin-right: 40px;
  }
  
  .glass-button{
    font-size: 22px;
  }
  .footer{
    font-size: 22px;
  }
}

@media only screen and (max-width: 1300px){
  .socials li{
    margin-right: 30px;
  }
  
  .glass-button{
    font-size: 20px;
  }

  .logo h3{
    font-size: 25px;
  }

  .logo img{
    width: 70px;
  }

  .logo .base-logo{
    width: 51px !important;
  }

  .footer{
    font-size: 22px;
  }
}

@media only screen and (max-width: 1100px){
  .socials{
    display: none;
  }

  .footer{
    padding: 5px;
   
  }
}

@media only screen and (max-width: 800px){
  .container{
    
    padding: 0 5%;
  }


  .logo h3{
    font-size: 20px;
  }

  .logo img{
    width: 60px;
  }

  .logo .base-logo{
    width: 41px !important;
  }

  .glass-card{
    margin: 30px auto;
  }
  .footer{
    width: 100%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 500px){
  .glass-card{
    width: 90%;
  } 

  .footer{
    width: 100%;
    font-size: 12px;
  }

  .buy-bobr-nav{
    display: none;
  }
}