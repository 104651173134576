.about-container{
    color: white;
    margin-bottom: 50px;
}

.about-container h1{
    text-align: center;
    font-size: 60px;
    margin: 70px 0 0;
}

.about-grid{
    display: flex;
    flex-direction: column;
}

.grid-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
}

.grid-row h1{
    font-size: 50px;
    margin-bottom: 15px;
}


.info,
.info-card{
    font-family: "Sawarabi Gothic", sans-serif;
    width: 80%;
    margin: 0 auto;
    font-size: 20px;
}

.info ul li{
    margin: 10px 0;
    font-size: 24px;
}

.list-title{
    font-family: "Rubik Doodle Shadow", system-ui;
    font-size: 30px;
    font-weight: 900;
    margin-right: 15px;
}
  
  

.react-image-follow-wrapper {
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
} 

.big-screen-image{
    display: block;
}

.small-screen-image{
    display: none;
    
}

@media only screen and (max-width: 1500px){
    .about-container h1{
        font-size: 50px;
    }

    .grid-row h1{
        font-size: 40px;
    }

    .info{
        font-size: 18px;
    }

    .info-card ul li{
        font-size: 20px;
    }

    .list-title{
        font-size: 25px;
    }

    .grid-row img{
        width: 60%;
    }
}

@media only screen and (max-width: 1300px){
    .about-container h1{
        font-size: 40px;
    }

    .grid-row h1{
        font-size: 30px;
    }

    .info{
        font-size: 16px;
    }

    .info-card ul li{
        font-size: 18px;
    }

    .list-title{
        font-size: 20px;
    }

    .grid-row img{
        width: 60%;
    }
}

@media only screen and (max-width: 1100px){
    .grid-row{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .grid-row img{
        width: 70%;
    }

    .big-screen-image{
        display: none;
    }

    .small-screen-image{
        display: block;
        
    }

    .info-card{
        width: fit-content;
        padding: 5px;
    }
    
}

@media only screen and (max-width: 500px){
    .info-card ul li{
        font-size: 14px;
    }

    .info-card ul{
        list-style: none;
        padding: 0;
    }
    .list-title{
        font-size: 16px;
    }
}