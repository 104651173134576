.hero{
  display: grid;
  grid-template-columns: 30% 70%;
  height: calc(100vh - 135px) ;
  max-height: 100vh;
}


/* ------------------------------QUOTE TEXT---------------------------- */
.quote{
    grid-column: 1 / span 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    height: 95%;
    max-height: 100vh;
    position: relative;
  }
  
  .quote-title h1{
    font-size: 60px;
    margin-bottom: 7px;
  }
  
  .quote-title h2{
    font-size: 35px;
    margin: 0;
  }
  
  .quote-description{
    width: 95%;
    font-family: "Sawarabi Gothic", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: rgb(168, 168, 168);
    font-size: 16px;
    margin: 30px 0;
  }
  
  .spinning{
    cursor: pointer;
  }

  .copy-address{margin-top: 10px;}

  .show-copy{
    display: block;
    animation: appear 0.5s 1;
  }

 .copy{
    display: none;
  }
  
  
  /* ------------------------------IMAGE CONTAINER---------------------------- */
  .image-container{
    grid-column: 2 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    max-height: 100vh;
  }
  
  .kurwa-image{
    width: 60%;
    display: none;
  }
  
  .kurwa-gif{
    border-radius: 100%;
    width: 50%;
    display: none;
  }
  
  .show{
    display: block;
    animation: appear 0.5s 1;
  }
  
  .bober-shouth{
    position: absolute;
    top: 0;
    color: white;
    font-size: 130px;
    animation: shoutAppear 1s infinite;
    display: none;
  }
  
  
  .show-shotout{
    display: block;
  }
  
  @keyframes appear {
    0%{
      transform: scale(0.1);
    } 
    100%{
      transform: scale(1);
    }
  }
  
  @keyframes shoutAppear {
    0%{
      left: -100%;
    } 
    100%{
      left: none;
    }
  }


@media only screen and (max-width: 1500px){
  .quote-title h1{
    font-size: 50px;
  }
  
  .quote-title h2{
    font-size: 30px;
  }


  .kurwa-image{
    width: 50%;
   
  }

  .bober-shouth{
    font-size: 100px;
  }
}

@media only screen and (max-width: 1300px){
  .quote-title h1{
    font-size: 40px;
  }
  
  .quote-title h2{
    font-size: 25px;
  }

  .quote-description{
    font-size: 14px;
    margin: 20px 0;
  }
  .bober-shouth{
    font-size: 90px;
  }
}

@media only screen and (max-width: 1100px){
  .hero{
    grid-template-columns: 40% 60%;
  }

  .kurwa-image{
    width: 70%;
  }
  .bober-shouth{
    font-size: 80px;
  }
}

@media only screen and (max-width: 800px){
  .hero{
    grid-template-columns: 50% 50%;
  }
}

@media only screen and (max-width: 600px){
  .hero{
    grid-template-columns: 100%;
    height: fit-content;
    
  }

  .image-container{
    grid-column: 1 / span 1;
  }

  .kurwa-image{
    width: 80%;
  }
  
  .bober-shouth{
    font-size: 70px;
  }
}

@media only screen and (max-width: 500px){
  .quote-title h1{
    font-size: 30px;
  }
  
  .quote-title h2{
    font-size: 20px;
  }

  .quote-description{
    font-size: 12px;
    margin: 15px 0;
  }
  .bober-shouth{
    font-size: 60px;
  }
}